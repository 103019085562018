import React from "react"

import styled from 'styled-components'

export const Title = styled.h2`
    font-size: 30px;
    filter: drop-shadow(2px 2px 2px black);
    text-transform: uppercase;
`

export const SendButton = styled.button`
    padding: 0 30px;
    color: #FFF;
    background-color: #39adff;
    border: none;
`

export default () => (
    <div className="contact-container">
        <Title>Contato</Title>
        <form className='contact' >
            <div className="inputs-item">
                <div className="input-container m-right">
                    <input id="name" type="text" required />
                    <label htmlFor="name">Nome</label>
                </div>
                <div className="input-container m-right">
                    <input id="email" type="email" required />
                    <label htmlFor="email">E-mail</label>
                </div>
                <div className="input-container">
                    <input id="phone" type="tel" required />
                    <label htmlFor="phone">Telefone</label>
                </div>
            </div>
            <div className="inputs-item">
                <div className="input-container">
                    <textarea id="mensagem" required ></textarea>
                    <label htmlFor="mensagem">Mensagem</label>
                </div>
            </div>
            <SendButton>Enviar</SendButton>
        </form>
    </div>
)