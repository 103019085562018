import React from "react"
import styled from 'styled-components'
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Image = () => {
    const data = useStaticQuery(graphql`
      query {
        file(relativePath: { eq: "jgv-cliente-site-em-blumenau.png" }) {
          childImageSharp {
            fixed(width: 220, height: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `);
    return <Img fixed={data.file.childImageSharp.fixed} aspectRatio />
  }

const Container =  styled.div`
  width: 230px;
`

export default () => (
    <Container>
      <Image/>
    </Container>
);