import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Icon from '@mdi/react'
import { mdiFacebook, mdiInstagram } from '@mdi/js'

import "../components/index-components/styles.scss"
import Security from '../components/index-components/icon-securety'
import Responsive from '../components/index-components/icon-responsive'
import Application from '../components/index-components/icon-applications'
import Sicredi from '../components/index-components/img-client-sicredi'
import Ypakey from '../components/index-components/img-client-ypakey'
import Jgv from '../components/index-components/img-client-jgv'
import Contact from '../components/index-components/Contact'

import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  color: #FFF;
  justify-content: center;
  height: 600px;
`

export const ContactContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    color: #FFF;
    justify-content: center;
    height: 400px;
`

export const Title = styled.h2`
  font-size: 20px;
  filter: drop-shadow(2px 2px 2px black);
`

export const Description = styled.h3`
  font-size: 50px;
  filter: drop-shadow(2px 2px 2px black);
  text-transform: uppercase;
`

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 350px;
`
export const VejaMais = styled.button`
  padding: 10px 15px;
  text-transform: uppercase;
  background-color: #39adff;
  color: #FFF;
  border: 0;
  width: 170px;
`
export const QuemSomos = styled.button`
  padding: 10px 15px;
  text-transform: uppercase;
  color: #39adff;
  background-color: #FFF;
  border: 0;
  width: 170px;
`

export const IconItem = styled.div`
  width: 200px;
`

export const TitleFooter = styled.h2`
  font-size: 25px;
  color: #FFF;
  filter: drop-shadow(2px 2px 2px black);
  text-transform: uppercase;
  text-align: center;
`

export const TitleItemFooter = styled.h3`
  font-size: 22px;
  color: #CCC;
  filter: drop-shadow(2px 2px 2px black);
  text-align: center;
`


const IndexPage = () => (
  <Layout>
    <SEO title="Aplicativos | Sites | Sistemas em Blumenau | Plathion" />
    <div className="bgimg-1">
    <Container>
        <Title>Desenvolvimento de sites e aplicativos</Title>
        <Description>Planeta Tecnológico</Description>
        <Buttons>
            <VejaMais>Veja Mais</VejaMais>
            <QuemSomos>Quem Somos</QuemSomos>
        </Buttons>
    </Container>
    </div>
    <div className="service-section blue-cut" >
      <div className="icon-container">
        <IconItem  className={'scale-button'} >
          <Link to="/" style={{
            textDecoration: `none`,
          }}
          >
            <Security />
          </Link>
        </IconItem>
        <IconItem className={'scale-button'} >
          <Link to="/" style={{
            textDecoration: `none`,
          }}
          >
            <Responsive />
          </Link>
        </IconItem>
        <IconItem className={'scale-button'} >
          <Link to="/" style={{
            textDecoration: `none`,
          }}
          >
            <Application />
          </Link>
        </IconItem>
      </div>
    </div>
    <div className="bgimg-2">
      <Contact />
    </div>
    <div className="service-section cut" >
      <div className="client-container" >
         <div className="title-client">ALGUNS DE NOSSOS CLIENTES</div>
         <div className="client-list">
          <Link to="/" style={{
            textDecoration: `none`,
          }}
          ><Ypakey /></Link>
        <Link to="/" style={{
            textDecoration: `none`,
          }}
          ><Sicredi /></Link>
          <Link to="/" style={{
            textDecoration: `none`,
          }}
          ><Jgv /></Link>
         </div>
      </div>
    </div>
    <div className="bgimg-3">
      <div className="footer-container">
        <div className="footer-item">
          <TitleFooter>Siga-nos</TitleFooter>
          <div className={'row flex-center-center'}  >
          <a href="https://www.facebook.com" target="_blank">
            <Icon path={mdiFacebook} title="Facebook" style={{ color: '#FFFFFF' }} size={2} />
          </a>
          <a href="https://www.instagram.com" target="_blank">
            <Icon path={mdiInstagram} title="Facebook" style={{ color: '#FFFFFF' }} size={2} />
          </a>
          </div>
        </div>
        <div className="footer-item">
          <TitleFooter>Contato</TitleFooter>
          <TitleItemFooter>47 9.9999-9999</TitleItemFooter>
        </div>
        <div className="footer-item">
          <TitleFooter>Sobre nós</TitleFooter>
          <Link to="/" style={{
            textDecoration: `none`,
          }}
        >
          <TitleItemFooter>História</TitleItemFooter>
        </Link>
          <Link to="/" style={{
            textDecoration: `none`,
          }}
        >
          <TitleItemFooter>Comunidade</TitleItemFooter>
        </Link>
          <Link to="/" style={{
            textDecoration: `none`,
          }}
        >
          <TitleItemFooter>Blog</TitleItemFooter>
        </Link>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
