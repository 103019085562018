import React from "react"
import styled from 'styled-components'
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Title = styled.div`
  margin: 5px;
  font-size: 18px;
  text-transform: uppercase;
  color: #5d5d5d;

`
const Description = styled.div`
  font-size: 16px;
  color: #5d5d5d;
`

const Image = () => {
    const data = useStaticQuery(graphql`
      query {
        file(relativePath: { eq: "aplicativos-react-blumenau.png" }) {
          childImageSharp {
            fixed(width: 150, height: 150) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `);
    return <Img fixed={data.file.childImageSharp.fixed} aspectRatio />
  }


export default () => (
    <div className="icon-services" >
      <Image />
      <Title>USABILIDADE</Title>
      <Description>Seus sites e aplicativos funcionando 24hrs por dia </Description>
    </div>
);